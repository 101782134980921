























import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {ContractCollection} from '@/model/collection/ContractCollection'

@Component
export default class FilterContractClientFiltered extends Vue {
  @Prop({type: Object, required: true}) collection!: ContractCollection

  get hasFilteredItems() {
    return Boolean(this.collection.blockchainVersion?.$id)
  }

  get blockchainVersions() {
    const {blockchainVersion} = this.collection
    if (!blockchainVersion?.$id) {
      return []
    }
    return [this.collection.blockchainVersion]
  }

  @Emit('filter')
  doClear() {
    this.collection.blockchainVersion = null
  }
}
