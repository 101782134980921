
















import _ from 'lodash'
import ECharts from 'vue-echarts/components/ECharts.vue'
import moment, {Moment} from 'moment'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Contract} from '@/model/resource/Contract'
import {ContractCounterGraphAssist} from '@/app/ContractCounterGraphAssist'
import {ContractCounter} from '@/model/resource/ContractCounter'
import {DappDetailedGraphPeriod} from '@/enums/DappDetailedGraphPeriod'

type Series = {
  data: any[]
  type: string
}

type Stats = {
  date: Moment
  transactionCount: number
}

@Component({
  components: {ECharts},
})
export default class ContractsChart extends Mixins(MixinScreenSize) {
  @Prop({type: Contract, required: true}) contract!: Contract

  period = DappDetailedGraphPeriod.FOURTEEN_DAYS

  cache = new ContractCounterGraphAssist(this.period, [
    this.contract.idContractPk,
  ])

  get series() {
    return this.xAxisData.reduce(
      (serie: Series, currentDate: Moment) => {
        const item = this.normalizedData.find(
          ({date}) => moment(date).dayOfYear() === currentDate.dayOfYear()
        ) as Stats

        return {
          ...serie,
          data: [
            ...serie.data,
            {
              value: item?.transactionCount || 0,
              itemStyle: {
                color: 'transparent',
              },
              symbolSize: 0,
            },
          ],
        }
      },
      ({
        data: [],
        type: 'line',
        lineStyle: {
          width: 3,
        },
      } as unknown) as Series
    )
  }

  get xAxisData(): Moment[] {
    const initialDate = moment().subtract(this.period, 'days')

    return Array.from({length: this.period}).map(() => {
      const nextDate = initialDate.add(1, 'day')
      return _.cloneDeep(nextDate)
    })
  }

  get normalizedData() {
    return this.cache.totalContractCounterList.map((item: ContractCounter) => ({
      date: moment(item.createdAt),
      transactionCount: item.transactionCount,
    }))
  }

  get getColorStops() {
    let positive = [
      {
        offset: 0,
        color: '#77fbaf', // color at 0% position
      },
      {
        offset: 1,
        color: '#134d89', // color at 100% position
      },
    ]
    let negative = [
      {
        offset: 0,
        color: '#fb77d5', // color at 0% position
      },
      {
        offset: 1,
        color: '#841706', // color at 100% position
      },
    ]
    if (this.cache.percentageGrow >= 0) return positive
    else return negative
  }

  get options() {
    return {
      xAxis: {
        type: 'category',
        data: this.xAxisData.map(item => item.date()),
        show: false,
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      series: this.series,
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: this.getColorStops,
        global: false, // false by default
      },
    }
  }

  async created() {
    await this.$await.run(`getDataChart_${this.contract.idContractPk}`, () =>
      this.cache.populate()
    )
  }
}
