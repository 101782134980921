












import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class CopyRender extends Vue {
  @Prop({type: String, required: true}) value!: string

  copyToClipboard() {
    this.$utils.copyToClipboard(this.value)
    this.$toast.info('system.info.copiedToClipboard')
  }
}
