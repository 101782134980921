





















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class NavHeaderMenu extends Vue {
  @Prop({type: String}) name!: string
  @Prop({type: Boolean, default: true}) showContractsView?: boolean
  @Prop({type: String, required: true}) toOverview!: string

  btnClass(name: string) {
    return {
      'nav-header-menu__btn--active': name === this.name,
    }
  }
}
