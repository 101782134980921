






































import {Component, Prop} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import {Contract} from '@/model/resource/Contract'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class DappCellRender extends MixinScreenSize {
  @Prop({type: Dapp, required: true}) value!: Dapp
  @Prop({type: Contract}) contract?: Contract

  get dapp() {
    return this.value
  }
}
