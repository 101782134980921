




















import {Component, Prop, Vue} from 'vue-property-decorator'
import {Contract} from '@/model/resource/Contract'

@Component
export default class ContractCellRender extends Vue {
  @Prop({type: Contract, required: true}) contract!: Contract
}
