
















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class DappsHeader extends Vue {
  @Prop({type: Boolean, default: false}) isDapp!: boolean
}
