var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen contracts-view"},[_c('navbar-menu',{staticClass:"mb-20"}),_c('div',{staticClass:"container"},[_c('dapps-header',{attrs:{"is-dapp":true}}),_c('nav-header-menu',{staticClass:"mb-16 mx-auto w-full md:w-160",attrs:{"name":"contracts","to-overview":"dappsOverview"}}),_c('filter-contract-client',{attrs:{"collection":_vm.collection,"sorter-field":_vm.sorterField}}),_c('adap-expansion',{staticClass:"mb-32 -mx-12 mt-6 md:mt-0",attrs:{"collection":_vm.collection,"spinner":"listContract"},scopedSlots:_vm._u([{key:"expand",fn:function(props){return [_c('div',{staticClass:"mt-32 verti items-center-center"},[_c('button',{staticClass:"btn btn--contrast bg-black h-10 md:w-2/12 text-lg font-medium",on:{"click":props.expandEvent}},[_vm._v(" "+_vm._s(_vm.$translate('app.viewMore'))+" ")])])]}}])},[_c('template',{slot:"notEmpty"},[_c('div',{staticClass:"px-8 md:px-12"},[_c('div',{staticClass:"p-2 md:p-6 bg-white rounded-none md:rounded-lg shadow-drop-md border-black-100 border"},[_c('div',{staticClass:"w-full overflow-x-auto"},[_c('table',{staticClass:"table table--ndapp"},[_c('thead',[_c('tr',[_vm._l((_vm.filterHeaders(_vm.schema.header)),function(value,key){return _c('th',{key:key,class:_vm.headerClass(key)},[_c('adap-orderby',{staticClass:"contracts-view__header :whitespace-normal text-base",attrs:{"collection":_vm.collection,"label":_vm.headerName(value, key),"name":key},on:{"afterQuery":_vm.onOrderBy}})],1)}),_c('th',[_c('adap-orderby',{staticClass:"contracts-view__header horiz text-right md:text-center justify-center whitespace-nowrap md:whitespace-normal text-base md:text-lg",attrs:{"collection":_vm.collection,"label":_vm.isMobile
                            ? _vm.schema.translateFrom('transactionsMobile')
                            : _vm.schema.translateFrom('transactions'),"name":"recentTransactionCount"},on:{"afterQuery":_vm.onOrderBy}})],1),_c('th',{staticClass:"select-none text-center"},[_c('div',{staticClass:"contracts-view__header"},[_c('adap-orderby',{staticClass:"contracts-view__header horiz text-right md:text-center justify-center  whitespace-nowrap md:whitespace-normal text-base md:text-lg",attrs:{"collection":_vm.collection,"label":_vm.isMobile
                              ? _vm.schema.translateFrom('last7DaysMobile')
                              : _vm.schema.translateFrom('last7Days'),"name":"percentageGrowth"},on:{"afterQuery":_vm.onOrderBy}})],1)]),_c('th')],2)]),_c('tbody',[_vm._l((_vm.collection.items),function(item,i){return [_c('tr',{key:item.idContractPk},[_vm._l((_vm.filterItems(_vm.schema.allFields)),function(field){return _c('td',{key:field.idContractPk,class:_vm.rowClass(field)},[[(_vm.isMobile)?_c('router-link',{attrs:{"to":{
                              name: 'dappDetailedView',
                              params: {id: item.slug},
                            }}},[_c('render-schema',{attrs:{"field":field,"schema":_vm.schema},model:{value:(_vm.collection.items[i]),callback:function ($$v) {_vm.$set(_vm.collection.items, i, $$v)},expression:"collection.items[i]"}})],1):_c('render-schema',{staticClass:"text-base md:text-lg",attrs:{"field":field,"schema":_vm.schema},model:{value:(_vm.collection.items[i]),callback:function ($$v) {_vm.$set(_vm.collection.items, i, $$v)},expression:"collection.items[i]"}})]],2)}),_c('td',[_c('div',{staticClass:"text-right md:text-center font-bold text-lg md:text-2xl text-bright-gray"},[_vm._v(" "+_vm._s(_vm.$filter.decimal(item.recentTransactionCount))+" ")])]),_c('td',[_c('percentage-progress',{attrs:{"percentage-value":item.percentageGrowth}})],1),_c('td',[_c('a',{staticClass:"table__expand-btn",class:{
                            'table__expand-btn--active': _vm.isExpanded(
                              item.idContractPk
                            ),
                          },on:{"click":function($event){return _vm.toggleExpand(item.idContractPk)}}})])],2),_c('tr',{key:((item.idContractPk) + "-e"),class:{
                        'table__skip-border': !_vm.isExpanded(item.idContractPk),
                      }},[_c('td',{staticClass:"p-0",attrs:{"colspan":_vm.filterItems(_vm.schema.allFields).length + 3}},[(_vm.isExpanded(item.idContractPk))?_c('await',{class:!_vm.isMobile && 'py-10',attrs:{"name":("getDapp_" + (item.idContractPk))}},[_c('transition-expand',{staticClass:"h-64"},[_c('dapp-detailed-summary',{staticClass:"py-5",attrs:{"dapp":_vm.dapp,"dappInfo":_vm.dappInfo,"is-dapp":true}})],1)],1):_vm._e()],1)])]})],2)])])])])]),_c('template',{slot:"empty"},[_c('h1',{staticClass:"text-6xl font-bold my-3"},[_vm._v(" "+_vm._s(_vm.$translate('view.dapps.noResults'))+" ")]),_c('div',{staticClass:"text-2xl font-semibold mb-12"},[_vm._v(" "+_vm._s(_vm.$translate('view.dapps.searchFor', {text: _vm.collection.search}))+" ")])])],2)],1),_c('app-footer',{attrs:{"add-to-list":"DAPP"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }